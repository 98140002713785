import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const teamDevelopmentDirector = {
    "blockId": uuidv4(),
    "description": "<p>Inspires, drives and connects actionables to the bigger picture. Optimizes teams' potential, capacity, and performance by holding team accountable.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Establishes Vision & Purpose"),
                    "subCategory": "Establishes Vision & Purpose",
                    "staticQuestionId": generateStaticQuestionId("Demonstrates an understanding of Applied's vision and enterprise aspirations"),
                    "HTMLText": "<p>Demonstrates an understanding of Applied's vision and enterprise aspirations</p>",
                    "isSelected": true,
                    "text": "Demonstrates an understanding of Applied's vision and enterprise aspirations",
                    "value": uuidv4()
                },
                // {
                //     "staticQuestionId": generateStaticQuestionId("Assigns clear accountability for objectives."),
                //     "HTMLText": "<p>Assigns clear accountability for objectives.</p>",
                //     "isSelected": true,
                //     "text": "Assigns clear accountability for objectives.",
                //     "value": uuidv4()
                // },
                {
                    "subCategoryId": generateStaticQuestionId("Establishes Vision & Purpose"),
                    "subCategory": "Establishes Vision & Purpose",
                    "staticQuestionId": generateStaticQuestionId("Translates complex strategies into ambitious, clear, actionable course of actions."),
                    "HTMLText": "<p>Translates complex strategies into ambitious, clear, actionable course of actions.</p>",
                    "isSelected": true,
                    "text": "Translates complex strategies into ambitious, clear, actionable course of actions.",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Establishes Vision & Purpose"),
                    "subCategory": "Establishes Vision & Purpose",
                    "staticQuestionId": generateStaticQuestionId("Inspires the team to leverage their creativity and potential to embrace ambitious goals and links them to strategic objectives"),
                    "HTMLText": "<p>Inspires the team to leverage their creativity and potential to embrace ambitious goals and links them to strategic objectives</p>",
                    "isSelected": true,
                    "text": "Inspires the team to leverage their creativity and potential to embrace ambitious goals and links them to strategic objectives",
                    "value": uuidv4()
                },

                
                {
                    "subCategoryId": generateStaticQuestionId("Organizes for Impact"),
                    "subCategory": "Organizes for Impact",
                    "staticQuestionId": generateStaticQuestionId("Ensures the team has the resources needed to successfully achieve its objectives"),
                    "HTMLText": "<p>Ensures the team has the resources needed to successfully achieve its objectives</p>",
                    "isSelected": true,
                    "text": "Ensures the team has the resources needed to successfully achieve its objectives",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Organizes for Impact"),
                    "subCategory": "Organizes for Impact",
                    "staticQuestionId": generateStaticQuestionId("Prioritizes relentlessly and motivates to drive an understanding of dependencies/ cross-team working"),
                    "HTMLText": "<p>Prioritizes relentlessly and motivates to drive an understanding of dependencies/ cross-team working</p>",
                    "isSelected": true,
                    "text": "Prioritizes relentlessly and motivates to drive an understanding of dependencies/ cross-team working",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Organizes for Impact"),
                    "subCategory": "Organizes for Impact",
                    "staticQuestionId": generateStaticQuestionId("Anticipates changes and provides mid-course correction to manage emerging realities & future needs"),
                    "HTMLText": "<p>Anticipates changes and provides mid-course correction to manage emerging realities & future needs</p>",
                    "isSelected": true,
                    "text": "Anticipates changes and provides mid-course correction to manage emerging realities & future needs",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Drives Accountability & Recognition"),
                    "subCategory": "Drives Accountability & Recognition",
                    "staticQuestionId": generateStaticQuestionId("Holds the team accountable for achieving results in a productive way"),
                    "HTMLText": "<p>Holds the team accountable for achieving results in a productive way</p>",
                    "isSelected": true,
                    "text": "Holds the team accountable for achieving results in a productive way",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Drives Accountability & Recognition"),
                    "subCategory": "Drives Accountability & Recognition",
                    "staticQuestionId": generateStaticQuestionId("Shows trust in team by delegating work and decisions, as appropriate; sets up systemic review mechanisms"),
                    "HTMLText": "<p>Shows trust in team by delegating work and decisions, as appropriate; sets up systemic review mechanisms</p>",
                    "isSelected": true,
                    "text": "Shows trust in team by delegating work and decisions, as appropriate; sets up systemic review mechanisms",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Drives Accountability & Recognition"),
                    "subCategory": "Drives Accountability & Recognition",
                    "staticQuestionId": generateStaticQuestionId("Provides timely recognition and feedback to motivate high performance."),
                    "HTMLText": "<p>Provides timely recognition and feedback to motivate high performance.</p>",
                    "isSelected": true,
                    "text": "Provides timely recognition and feedback to motivate high performance.",
                    "value": uuidv4()
                },
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "High Performance Team"
}