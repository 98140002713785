import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const strengthComments = (isCheckBoxNeeded) => {
    return {
        "blockId": uuidv4(),
        "goalId": "commentsOnStrength",
        "isHiddenFromEditSurvey": true,
        "questions": [],
        "type": "priorityComments",
        "dimension": "commentsOnStrength",
        "isCheckboxNeeded": isCheckBoxNeeded
    }

}

export const strengthCommentsAppliedMaterials=(isCheckBoxNeeded)=>{
    return {
        "selfInstructionHeader": "<p><b>EXAMPLES OF TOP STRENGTHS: </b>Take a moment to reflect and share examples of how you demonstrated these strengths to get better outcomes.</p> ",
        "instructionHeader": "<p><b>EXAMPLES OF TOP STRENGTHS: </b>Please share examples of this leader's top strengths.</p> <p>Note: Besides Self and Manager, your input is grouped together with other comments but will be shown verbatim (exactly as written, in any language) in the report. Avoid mentioning details that may identify you or others.</p> ",
        "blockId": uuidv4(),
        "goalId": "commentsOnStrength",
        "isHiddenFromEditSurvey": true,
        "questions": [],
        "type": "priorityComments",
        "dimension": "commentsOnStrength",
        "isCheckboxNeeded": isCheckBoxNeeded
    }
}

export const strengthCommentsAppliedMaterialsDirector=(isCheckBoxNeeded)=>{
    return {
        "selfInstructionHeader": "<p><b>EXAMPLES OF TOP STRENGTHS: </b>Take a moment to reflect and share examples of how you demonstrated these strengths to get better outcomes.</p> ",
        "instructionHeader": "<p><b>EXAMPLES OF TOP STRENGTHS: </b>Please share examples of this leader's top strengths. We encourage you to share examples that would help the leader better understand how to further leverage these strengths. These examples are highly valued by 360 feedback recipients.</p> ",
        "blockId": uuidv4(),
        "goalId": "commentsOnStrength",
        "isHiddenFromEditSurvey": true,
        "questions": [],
        "type": "priorityComments",
        "dimension": "commentsOnStrength",
        "isCheckboxNeeded": isCheckBoxNeeded
    }
}