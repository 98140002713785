import {
  RESET_WELCOME_MESSAGE_DEMOGRAPHICS_STATE,
  SET_SETUP_LOCAL_STATE_DATA,
  SET_WELCOME_MESSAGE_DEMOGRAPHICS_STATE
  } from "../../../constants";
  
export const setLocalStateData = (payload, cb) => {
    return (dispatch) => {
      dispatch({ type: SET_SETUP_LOCAL_STATE_DATA ,payload});
    }
  };

export const setWelcomeMessageDemographicsFilter = (payload, cb) => {
  return (dispatch) => {
    dispatch({ type: SET_WELCOME_MESSAGE_DEMOGRAPHICS_STATE, payload });
  }
};

export const resetWelcomeMessage = (payload) => {
  return (dispatch) => {
    dispatch({ type: RESET_WELCOME_MESSAGE_DEMOGRAPHICS_STATE, payload });
  }
}