import { COHORT_SURVEY_TYPE, FEEDBACK_360_TYPE } from "../../../../../../config/questionTypeConstants";
import { deepCopy } from "../../../../../utils/deepCopy";

export const fetchHRISDataStart = (state, payload) => {
    return {
        ...state,
        HRISFilterData: {
            isProcessing: true,
            error: false,
            reason: '',
            facetData: [],
        },
    };
};

export const fetchHRISDataSuccess = (state, payload) => {
    const data = payload.data.data
    const demographics = getDemographics(data)
    return {
        ...state,
        HRISFilterData: {
            isProcessing: false,
            error: false,
            reason: '',
            demographics: demographics,
            welcomeMessageFilter: IswelcomeMessageFilterNeeded(state, demographics) ? generateWelcomeMessageDemographicsData(data.relation) : [],
            data: data,
        },
    };
};

export const fetchHRISDataFail = (state, payload) => {
    return {
        ...state,
        HRISFilterData: {
            isProcessing: false,
            error: true,
            reason: payload.error,
            facetData: [],
        },
    };
};

const getDemographics = (data) => {
    return Object.keys(data)
}

const IswelcomeMessageFilterNeeded = (state, demographics) => {
    if (state.surveyWizardProjectDetailsApi.data && state.surveyWizardProjectDetailsApi.data && state.surveyWizardProjectDetailsApi.data.projectType) {
        if (state.surveyWizardProjectDetailsApi.data.projectType === COHORT_SURVEY_TYPE || FEEDBACK_360_TYPE.includes(state.surveyWizardProjectDetailsApi.data.projectType)) {
            return demographics.includes('relation')
        }
    }
    return false
}

const generateWelcomeMessageDemographicsData = (relationData) => {
    let relationDataCopy = deepCopy(relationData)
    relationDataCopy = relationDataCopy.sort((item1, item2) => {
        if (item1 === "self") return -1;
        if (item2 === "self") return 1;
        return 0;
    })
    relationDataCopy = [...new Set(relationDataCopy)]
    let dropDownData = []
    let nonSelfData = []
    relationDataCopy.forEach((relation) => {
        if (relation === "self") {
            dropDownData.push({
                title: relation,
                value: relation
            })
        }
        else {
            nonSelfData.push({
                title: relation,
                value: relation,
            })
        }

    })
    if (nonSelfData.length > 0) {
        dropDownData.push(
            {
                title: "non-self",
                value: "non-self",
                children: nonSelfData
            }
        )
    }
    return dropDownData
}

