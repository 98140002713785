import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const coachingDirector = {
    "blockId": uuidv4(),
    "description": "<p>Scales capacity by maximizing the performance and potential of future talent. Engages to enable direct and meaningful feedback that empowers skill development and realization of potential.</p>",
    "goalId": "dynamic360Strength",
    isRequired: true,
    feedbackBasedCondition: [
        {
            answeringCondition: [
                {
                    columnValues: ["1", "2"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["3", "4"],
                    numberOfAnswersNeeded: 3,
                },
                {
                    columnValues: ["5", "6"],
                    numberOfAnswersNeeded: 3,
                }
            ]
        }

    ],
    allowNAOption: true,
    "questions": [
        {
            "question": "",
            "options": [
                {
                    "label": "Never",
                    "value": "1"
                },
                {
                    "label": "Rarely",
                    "value": "2"
                },
                {
                    "label": "Occasionally",
                    "value": "3"
                },
                {
                    "label": "Sometimes",
                    "value": "4"
                },
                {
                    "label": "Usually",
                    "value": "5"
                },
                {
                    "label": "Always",
                    "value": "6"
                },
                { "label": "Can't answer", "value": "0" }
            ],
            "questionDimension": "Custom Matrix",
            "type": "matrix",
            "qid": uuidv4(),
            "matrixQuestions": [
                {
                    "subCategoryId": generateStaticQuestionId("Drives High Velocity Performance "),
                    "subCategory": "Drives High Velocity Performance ",
                    "staticQuestionId": generateStaticQuestionId("Holds teams/ individual members accountable for achieving high quality results"),
                    "HTMLText": "<p>Holds teams/ individual members accountable for achieving high quality results</p>",
                    "isSelected": true,
                    "text": "Holds teams/ individual members accountable for achieving high quality results",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Drives High Velocity Performance "),
                    "subCategory": "Drives High Velocity Performance ",
                    "staticQuestionId": generateStaticQuestionId("Enables balance between high intensity work and rest and rejuvenation"),
                    "HTMLText": "<p>Enables balance between high intensity work and rest and rejuvenation</p>",
                    "isSelected": true,
                    "text": "Enables balance between high intensity work and rest and rejuvenation",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Drives High Velocity Performance "),
                    "subCategory": "Drives High Velocity Performance ",
                    "staticQuestionId": generateStaticQuestionId("Communicates strategic direction and brings team members along in developing and implementing plans"),
                    "HTMLText": "<p>Communicates strategic direction and brings team members along in developing and implementing plans</p>",
                    "isSelected": true,
                    "text": "Communicates strategic direction and brings team members along in developing and implementing plans",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Optimizes Potential Realization"),
                    "subCategory": "Optimizes Potential Realization",
                    "staticQuestionId": generateStaticQuestionId("Inspires team members to raise the performance bar consistently"),
                    "HTMLText": "<p>Inspires team members to raise the performance bar consistently</p>",
                    "isSelected": true,
                    "text": "Inspires team members to raise the performance bar consistently",
                    "value": uuidv4()
                },

                {
                    "subCategoryId": generateStaticQuestionId("Optimizes Potential Realization"),
                    "subCategory": "Optimizes Potential Realization",
                    "staticQuestionId": generateStaticQuestionId("Proactively provides assignments that stretch and grow team/ team member's  capabilities"),
                    "HTMLText": "<p>Proactively provides assignments that stretch and grow team/ team member's  capabilities</p>",
                    "isSelected": true,
                    "text": "Proactively provides assignments that stretch and grow team/ team member's  capabilities",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Optimizes Potential Realization"),
                    "subCategory": "Optimizes Potential Realization",
                    "staticQuestionId": generateStaticQuestionId("Provides on-going and timely coaching to support personal & professional growth"),
                    "HTMLText": "<p>Provides on-going and timely coaching to support personal & professional growth</p>",
                    "isSelected": true,
                    "text": "Provides on-going and timely coaching to support personal & professional growth",
                    "value": uuidv4()
                },
                {
                    "subCategoryId": generateStaticQuestionId("Feed-Forwards With Candor"),
                    "subCategory": "Feed-Forwards With Candor",
                    "staticQuestionId": generateStaticQuestionId("Challenges the status quo by identifying bold actions for implementation"),
                    "HTMLText": "<p>Challenges the status quo by identifying bold actions for implementation</p>",
                    "isSelected": true,
                    "text": "Challenges the status quo by identifying bold actions for implementation",
                    "value": uuidv4()
                },
              
                {
                    "subCategoryId": generateStaticQuestionId("Feed-Forwards With Candor"),
                    "subCategory": "Feed-Forwards With Candor",
                    "staticQuestionId": generateStaticQuestionId("Encourages team members to take initiative in determining how best to drive team outcomes"),
                    "HTMLText": "<p>Encourages team members to take initiative in determining how best to drive team outcomes</p>",
                    "isSelected": true,
                    "text": "Encourages team members to take initiative in determining how best to drive team outcomes",
                    "value": uuidv4()
                },
             
                {
                    "subCategoryId": generateStaticQuestionId("Feed-Forwards With Candor"),
                    "subCategory": "Feed-Forwards With Candor",
                    "staticQuestionId": generateStaticQuestionId("Initiates difficult conversations in a timely manner to drive optimal outcomes"),
                    "HTMLText": "<p>Initiates difficult conversations in a timely manner to drive optimal outcomes</p>",
                    "isSelected": true,
                    "text": "Initiates difficult conversations in a timely manner to drive optimal outcomes",
                    "value": uuidv4()
                },

              
              
               
            ],
            "questionLabel": "Custom Matrix"
        }
    ],
    "isSelected": true,
    "type": "matrix",
    "dimension": "Coaching"
}