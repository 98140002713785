import { deepCopy } from "../../../../utils/deepCopy";

export const setLocalStateData = (state, payload) => {
    return {
        ...state,
        localStateData: {
            ...state.localStateData,
            ...payload
        }
    };
};

export const setWelcomeMessageFilter = (state, payload) => {
    return {
        ...state,
        currentWelcomeMessageUser: payload.value
    };
};

export const resetLocalStateData = (state, payload) => {
    let newLocalstateData = removeUserKeys(state.localStateData)
    return {
        ...state,
        currentWelcomeMessageUser: undefined,
        localStateData: {
            ...newLocalstateData,
        }
    };
};

const removeUserKeys = (data) => {
    let dataCopy = deepCopy(data)
    Object.keys(dataCopy).forEach(key => {
        if (key.startsWith("aboutSurvey#/")) {
            dataCopy[key] = null;
        }
    });
    return dataCopy
}