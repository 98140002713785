
import { v4 as uuidv4 } from "uuid";
import { generateStaticQuestionId } from "../../../../../utils/goalsUtils";

export const improvementComments = (isCheckBoxNeeded) => {
    return {
        "blockId": uuidv4(),
        "goalId": "commentsOnImprovement",
        "isHiddenFromEditSurvey": true,
        "questions": [],
        "type": "priorityComments",
        "dimension": "commentsOnImprovement",
        "isCheckboxNeeded": isCheckBoxNeeded

    }

}

export const improvementCommentsForApplied = (isCheckBoxNeeded) => {
    return {
        "instructionHeader": "<p><b>EXAMPLES OF TOP DEVELOPMENT AREAS: </b>Please share examples or anecdotes of these development areas.</p><p>Note: Besides Self and Manager, your input is grouped together with other comments but will be shown verbatim (exactly as written, in any language) in the report. Avoid mentioning details that may identify you or others.</p>",
        "selfInstructionHeader": "<p><b>EXAMPLES OF TOP DEVELOPMENT AREAS: </b>Take a moment to reflect and write examples when you didn’t demonstrate these behaviors enough.</p>",
        "blockId": uuidv4(),
        "goalId": "commentsOnImprovement",
        "isHiddenFromEditSurvey": true,
        "questions": [],
        "type": "priorityComments",
        "dimension": "commentsOnImprovement",
        "isCheckboxNeeded": isCheckBoxNeeded

    }

}

export const improvementCommentsForAppliedDirector = (isCheckBoxNeeded) => {
    return {
        "instructionHeader": "<p><b>EXAMPLES OF TOP DEVELOPMENT AREAS: </b>Please share examples of this leader's top development areas. Your feedback is very valuable to help clarify how the leader could improve on these development areas. These examples are highly valued by 360 feedback recipients.</p>",
        "selfInstructionHeader": "<p><b>EXAMPLES OF TOP DEVELOPMENT AREAS: </b>Take a moment to reflect and write examples when you didn’t demonstrate these behaviors enough.</p>",
        "blockId": uuidv4(),
        "goalId": "commentsOnImprovement",
        "isHiddenFromEditSurvey": true,
        "questions": [],
        "type": "priorityComments",
        "dimension": "commentsOnImprovement",
        "isCheckboxNeeded": isCheckBoxNeeded

    }

}